.cafe-hills-logo {
  /* position: absolute;
  width: 300px;
  left: -50px;
  top: -50px; */
  width: 100%;
}
.cafe-hills-menu-board {
  width: 100%;
}
